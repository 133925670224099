// https://codepen.io/kartar_developer/pen/abGGYgN

@use 'variables' as *;

.container {
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: $dkBlue;
}
 
.loader {
   width: 7.5rem;
   height: 7.5rem;
   border-top: 0.25rem solid $accent;
   border-radius: 50%;
   animation: spin 0.8s linear infinite;
}
 

@keyframes spin {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
}
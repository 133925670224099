@use 'mixins' as *;

//Navigation
$mobileNavHeight: 3.125rem;
$desktopNavHeight: 4.375rem;
$landingNavHeight: 3.125rem;

//Footer 
//footer and nav use same breakpoint
$footerBreakpoint: 56.25rem;
$footerHeightMobile: 24.375rem;
$footerHeightDesktop: 14.5rem;

// Colors

$bgDark: #00050B; //Rich Black
$dkBlue: #001229; //Oxford Blue
$medBlue: #11203E; //lighter shade of Oxford


$aboveFoldDropShadow: #010e21; //matches background of main image

$accent: #51D7FD; //vivid blue 
//other accent from hero image is FD51F6 Purple Pizzazz

// $ZAaccent: #ff94b6; 
// $ZAaccent: #FF5C8D;
$ZAaccent: #ED156E; // for South Africa page

$light: #CFD9DD; // light grey
$medium: #999999;


// Font Colors
$headingLight: white;
$bodyLight: #EBEBEB;
$navLight: #F5F5F5;

// Fonts
$headingFont: 'Outfit', sans-serif; //no italics, 
$bodyFont: 'Open Sans', sans-serif;
$archiveBannerFont: 'Caveat', 'Outfit', sans-serif;

// Landing Page
$castInfoHeight: 5.625rem;
$castInfoHeightLrg: 6.25rem;

$landingTopFooterDesktop: 12.5rem;
$landingTopFooterMobile: 390px;
$landingBottomFooter: 8.75rem;
$landingFooterBreakpoint: 56.25rem;
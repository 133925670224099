@use 'variables' as *;

body {
   margin: 0;
   font-family: $bodyFont;
   font-weight: 500;
   font-size: 16px;
   line-height: 1.25;
   color: $bodyLight;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: $bgDark;
   overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
   font-family: $headingFont;
   font-weight: 600;
   color: $headingLight;
}

b, strong {
   font-weight: 700;
}
button {
   display: block;
   background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
a,
a:visited,
a:focus,
a:hover,
a:active {
   color: white;
   text-decoration: none;
}

p {
   word-spacing: 0.1em;
}

article {
   margin: 25px auto;
   max-width: 50rem;
}

img {
   width: 100%;
   height: auto;
   aspect-ratio: attr(width) / attr(height);
} 

.visually-hidden {
   clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
   height: 0.0625rem;
   overflow: hidden;
   position: absolute;
   white-space: nowrap;
   width: 0.0625rem;
}
.visually-hidden:focus {
   clip: auto;
   height: auto;
   overflow: auto;
   position: absolute;
   width: auto;
}

.page-wrap {
   position: relative;
   min-height: 100vh;
   background-color: $dkBlue;
}

.content-wrap {
   margin: 0 auto;
   padding: calc($mobileNavHeight + 1.5625rem) 1.25rem $footerHeightMobile;
   max-width: 62.5rem;
   background-color: $dkBlue;

   @media screen and (min-width: $footerBreakpoint) {
      padding: calc($desktopNavHeight + 1.5625rem) 1.25rem $footerHeightDesktop;
   };
}

// Skip to Content
a.skip-to-content-link {
   background: orange;
   color: black;
   left: calc(50% - 4.0625rem); //4.0625rem is ~half the width of the element
   padding: 0.75rem;
   border-radius: 0.3125rem;
   position: absolute;
   transform: translateY(-200%);
   transition: transform 0.3s;
   z-index: 100;
}

a.skip-to-content-link:focus {
   transform: translateY(20%);
   z-index: 100;
}

.uppercase {
   text-transform: uppercase;
}

.wysiwyg,
.pageTitle {
   margin: 0 auto;
   padding-bottom: 1.5625rem;
   width: 100%;
   max-width: 50rem;
   word-wrap: break-word;

   a:link,
   a:focus,
   a:active,
   a:hover,
   a:visited {
      color: $accent;
      text-decoration: underline;
   }
  
   dd {
      margin-inline-start: 20px;
   }
}
.wysiwyg {
   :target {
      scroll-margin-top: calc($mobileNavHeight + 6.25rem);
      @media screen and (min-width: 900px) {
         scroll-margin-top: calc($desktopNavHeight + 6.25rem);
      }
   }
}

.aligncenter {
   display: block;
   margin: 0 auto;
}

// Carousel (Blog/Landing Flex Content)
// uses Slick default classes (custom classes are in posts.module.scss)
.slick-next {
   margin-right: 0.625rem;
   z-index: 10;
}
.slick-prev {
   margin-left: 0.625rem;
   z-index: 10;
}

ul.slick-dots {
   padding-top: 0.9375rem;
}

.slick-dots li button:before {
   color: lightgrey !important;
}
.slick-dots li.slick-active button:before {
   color: grey !important;
}
